import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"

import SEO from "../components/seo"
// import { Link } from "gatsby"

const MeetTheTeam = () => (
  <Layout>
    <SEO
      title="Contact us"
      description="Our experienced and highly trained team are here to ensure that the health and welfare of your animals are taken care of."
    />
    <section>
      <div className="container">
        <h1 className="section-heading">Meet the Team</h1>
        <div className="row pb-5">
          <div className="col-lg-6 d-flex align-items-center mt-4 mt-md-0">
            <div>
              <h5 className="mb-4">
                Jessica Byrnes-Clark BSc BVT - Managing Director & Technologist
              </h5>
              <p>
                Jessica has over five years of experience in both private
                practice and Massey University where a love of lifestyle block
                medicine and management was fostered. Jessica is originally from
                the “winterless” North but is starting to enjoy the cold climate
                the Central Plateau has to offer.
              </p>
              <p className="mb-0">
                She is a big fan of preventative based health, engages in low
                stress animal handling and enjoys working with all of the
                different farm animals and their owners. Outside of work Jessica
                you will find her enjoying the great outdoors, making a mess in
                the kitchen or looking after her “kids”; the dogs, turtles,
                goats and boyfriend Chris.
              </p>
            </div>
          </div>
          <div className="col-lg-6 d-flex align-items-center mt-4 mt-lg-2 mb-4">
            <StaticImage
              aspectRatio={16 / 11}
              src="../images/jessica.jpg"
              alt="Jessica Byrnes-Clark"
              loading="eager"
            />
          </div>
        </div>
        <hr />
        <div className="row  pt-5 mt-2">
          <div className="col-lg-6 d-flex align-items-center order-lg-last">
            <div>
              <h5 className="mb-4">Dr Danielle Gifford BVSc - Veterinarian</h5>
              <p>
                Danielle has over ten years of farm animal veterinary experience
                both in private practice and at Massey University and is focused
                on preventing disease and maximising welfare in animals through
                targeted animal health planning.
              </p>
              <p>
                As a lifestyle block owner herself she understands the unique
                challenges small block farmers face, whether farming animals for
                profit or pleasure. Danielle loves living in rural New Zealand
                and enjoying all it has to offer with her husband and children
                (and animal menagerie).
              </p>
            </div>
          </div>
          <div className="col-lg-6 mt-4 mt-lg-2 mb-4">
            <StaticImage
              aspectRatio={16 / 11}
              src="../images/danielle.jpg"
              alt="Dr Danielle Gifford BVSc - Veterinarian"
              loading="eager"
            />
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default MeetTheTeam
